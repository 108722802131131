/*
// jei reikia, galime perrasyti core komponentus, pvz.
init_tooltips = function(context) {
	$('[data-toggle="tooltip"]', context).tooltip({placement:'bottom'});
}
*/

var screen_xs = 480;
var screen_xs_min = screen_xs;
var screen_phone = screen_xs_min;
var screen_sm = 768;
var screen_sm_min = screen_sm;
var screen_tablet = screen_sm_min;
var screen_md = 992;
var screen_md_min = screen_md;
var screen_desktop = screen_md_min;
var screen_lg = 1200;
var screen_lg_min = screen_lg;
var screen_lg_desktop = screen_lg_min;
var screen_xs_max = (screen_sm_min - 1);
var screen_sm_max = (screen_md_min - 1);
var screen_md_max = (screen_lg_min - 1);

function get_vw() {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}

function get_vh() {
    return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
}

$(function(){
    init_page_editor_slider(document);
});

function init_page_editor_slider(context) {
    if ( get_vw() < 768 ) {
        $('.page_editor_columns_carousel').each(function() {
            if ($(this).find('.carousel-inner-x .item').length > 1 && !$(this).find('.carousel-inner-x').hasClass('owl-carousel')) {
                $(this).find('.carousel-inner-x').addClass('owl-carousel');
                var carousel = $(this).find('.carousel-inner-x');

                carousel.owlCarousel({
                    autoHeight: true,
                    loop: true,
                    autoplay: false,
                    // autoplayTimeout: 5000,
                    // autoplayHoverPause: true,
                    stagePadding: 2,
                    nav: false,
                    responsiveClass: true,
                    slideBy: 'page',
                    items: 1,
                    dots: true,
                });
            }
        });
    } else {
        var owl = $('.page_editor_columns_carousel .owl-carousel');
        owl.trigger('destroy.owl.carousel');
        owl.removeClass('owl-carousel');
    }
}

// Hide header on scroll down, show on scroll up
$(function () {
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = $('nav').outerHeight();

    setTimeout(function() {
        update_nav_top();
    }, 300);

    $(window).on('resize', function() {
        update_nav_top();
        init_page_editor_slider(document);
    });

    $(window).scroll(function(event){
        didScroll = true;
    });

    if ($(window).width() >= 768) {
        setInterval(function () {
            if (didScroll) {
                hasScrolled();
                didScroll = false;
            }
        }, 250);
    }

    function hasScrolled() {
        var st = $(this).scrollTop();

        // Make sure they scroll more than delta
        if(Math.abs(lastScrollTop - st) <= delta)
            return;

        // If they scrolled down and are past the navbar, add class .nav-up.
        // This is necessary so you never see what is "behind" the navbar.
        if (st > lastScrollTop && st > navbarHeight){
            // Scroll Down
            $('nav').removeClass('nav-down').addClass('nav-up');
        } else {
            // Scroll Up
            if(st + $(window).height() < $(document).height()) {
                $('nav').removeClass('nav-up').addClass('nav-down');
            }
        }

        lastScrollTop = st;
    }

    $(document).on('click', 'div.list-collapse-mobile > .title', function(e){
        if (get_vw() <= screen_sm_max) {
            var header_height = $('header').height();
            if ($('.linepromos').length) {
                var promo_height = $('.linepromos').height();
            } else {
                var promo_height = 0;
            }
            var nav_top = header_height + promo_height;

            $('#pages_mega_menu #mega_menu .list-dropdown .submenu_list').css('top', nav_top + 'px');
        }
    });

    function update_nav_top() {
        var header_height = $('header').height();
        if ($('.linepromos').length) {
            var promo_height = $('.linepromos').height();
        } else {
            var promo_height = 0;
        }
        var nav_top = header_height + promo_height;
        var submenu_height = get_vh();

        if (get_vw() >= screen_md_min) {
            $('nav').css('top', nav_top + 'px');
            content_padding = 0;
        } else {
            $('nav, #pages_mega_menu #mega_menu .list-dropdown .submenu_list, #pages_mega_menu #mega_menu .list-dropdown .row.level-3').css('top', nav_top + 'px');
            content_padding = promo_height;
        }

        $('#content_layout').css('padding-top', content_padding + 'px');
        submenu_height = submenu_height - nav_top;

        if (get_vw() <= screen_sm_max) {
            $('nav').css('height', submenu_height + 'px');
        } else {
            $('nav').css('height', '');
        }
    }
});

$(document).ready(function() {
    if ($(window).width() > 991) {
        $(window).resize(function() {
            location.reload();
        });

        $(window).on('wheel', function(event) {
            if ($('body').hasClass('fp-viewing-3')) {
                if (event.originalEvent.wheelDelta > 0 || event.originalEvent.detail < 0) {
                    $('.navigation-block .next-page').removeClass('show');
                } else {
                    $('.navigation-block .prev-page').removeClass('show');
                }
            }
        });

        let scrollFromBrands = false;
        $('#fullpage').fullpage({
            autoScrolling: true,
            scrollingSpeed: 1800,
            responsiveWidth: 991,
            scrollOverflow: true,
            onLeave: function(origin, destination, direction) {
                if (destination == 4) {
                    setTimeout(function() {
                        if (direction === 'down') {
                            $('.navigation-block .prev-page').addClass('show');
                        }
                        if (direction === 'up') {
                            $('.navigation-block .next-page').addClass('show');
                        }
                    }, 500);
                    return true;
                }
                if (origin == 4) {
                    if (scrollFromBrands === direction) {
                        scrollFromBrands = false;
                        $('.navigation-block button').removeClass('show');
                        return true;
                    } else {
                        setTimeout(function() {
                            scrollFromBrands = direction;
                            if (direction === 'down') {
                                $('.navigation-block .next-page').addClass('show');
                            }
                            if (direction === 'up') {
                                $('.navigation-block .prev-page').addClass('show');
                            }
                        }, 500);
                        return false;
                    }
                }
            }
        });

        $('.navigation-block .next-page').click(function (e) {
            e.preventDefault();
            $.fn.fullpage.moveSectionDown();
            if ($('body').hasClass('fp-viewing-3')) {
                setTimeout(function() {
                    $.fn.fullpage.moveSectionDown();
                }, 500);
            }
        });
        $('.navigation-block .prev-page').click(function (e) {
            e.preventDefault();
            $.fn.fullpage.moveSectionUp();
            if ($('body').hasClass('fp-viewing-3')) {
                setTimeout(function() {
                    $.fn.fullpage.moveSectionUp();
                }, 500);
            }
        });

        $('.menu-hero').click(function (e) {
            e.preventDefault();
            $.fn.fullpage.moveTo(2);
        });
        $('.menu-about').click(function (e) {
            e.preventDefault();
            $.fn.fullpage.moveTo(3);
        });
        $('.menu-brands').click(function (e) {
            e.preventDefault();
            $.fn.fullpage.moveTo(4);
        });
        $('.menu-contacts').click(function (e) {
            e.preventDefault();
            $.fn.fullpage.moveTo(5);
        });
        $('.menu-subscribe').click(function (e) {
            e.preventDefault();
            $.fn.fullpage.moveTo(6);
        });
    } else {
        $('.navigation-block .next-page').click(function (e) {
            e.preventDefault();
            $('html').addClass('open');
        });
        $('.slider').addClass('owl-container');
        $('.slider').each(function () {
            // Add main classes
            $(this).addClass('owl-carousel');
            // Options
            $(this).owlCarousel({
                autoHeight: false,
                loop: true,
                autoplay: true,
                autoplayTimeout: 5000,
                autoplayHoverPause: true,
                nav: false,
                responsiveClass: true,
                slideBy: 'page',
                dots: true,
                responsive: {
                    0: {
                        items: 1
                    },
                    991: {
                        items: 1
                    },
                }
            });
        });

        $('.burger').click(function() {
            $('body').toggleClass('open-menu');
        });

        $('button.menu-link').click(function(e) {
            e.preventDefault();
            $('body').removeClass('open-menu');
            let section = $(this).data('section');
            $('html, body').animate({
                scrollTop: $('.' + section).offset().top - 90
            }, 1000);
        });

        $('.info-section .next-page').click(function(e) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: $('.brands-page').offset().top - 90
            }, 1000);
        });

        $('#brands_listing .img-fluid, #brands_listing .show-more').click(function(e) {
            e.preventDefault();
            $('#brands_listing .col').removeClass('open');
            $(this).parent().addClass('open');
        });
    }
});